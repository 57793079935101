<template>
    <!-- CONTENT ================================================== -->
    <div>

<!-- breadcrumb --> 
<div class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><i class="fas fa-building"></i></li>
          <li class="breadcrumb-item"> <i class="fas fa-chevron-right"></i>{{ translateAktif == 'id' ? 'Cara Order' : 'How To Order'}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<!--=================================
breadcrumb -->

<section class="space-ptb">
    <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="section-title">
          <h2>{{ translateAktif == 'id' ? 'Cara Order' : 'How To Order'}}</h2>
        </div>
      </div>
      <div class="col-lg-6">
        
      </div>
    </div>
    <div class="hide-on-mobile row">
      <div style="width:20%" class="">
        <div class="feature-info feature-info-02">
          <div class="feature-info-detail">
            <div class="feature-info-icon">
              <!-- <i class="flaticon-agent"></i> -->
              <img style="width:50%" class="img-fluid" :src="getImage(`assets/icon/konsultasi.png`)"  alt="">
            </div>
            <div class="feature-info-content">
              <h6 class="mb-3 feature-info-title">1. {{ translateAktif == 'id' ? 'Konsultasi' : 'Consultation'}}</h6>
              <p v-if="translateAktif == 'id'">Konsultasi detail orderan ke HASINA</p>
              <p v-if="translateAktif == 'en'">Consult order details with HASINA</p>
            </div>
            <div class="feature-info-button">
              <a class="btn btn-light btn-block" href="https://wa.me/6285740177785?text=Halo+Hasina...+Ada+yang+perlu+saya+konsultasikan" target="_blank">{{ translateAktif == 'id' ? 'Chat Sekarang' : 'Chat Now'}}</a>
            </div>
          </div>
          <div class="feature-info-bg bg-holder bg-overlay-black-70" style="background-image: url('../templates/default/images/property/grid/02.jpg');"></div>
        </div>
      </div>
      <div style="width:20%" class="">
        <div class="feature-info feature-info-02">
          <div class="feature-info-detail">
            <div class="feature-info-icon">
              <!-- <i class="flaticon-note"></i> -->
              <img style="width:50%" class="img-fluid" :src="getImage(`assets/icon/payment.png`)"  alt="">
            </div>
            <div class="feature-info-content">
              <h6 class="mb-3 feature-info-title">2. {{ translateAktif == 'id' ? 'Uang Muka' : 'Down Payment'}}t</h6>
              <p v-if="translateAktif == 'id'">Terima Nota/Invoice DP dari HASINA (DP minimal 50%)</p>
              <p v-if="translateAktif == 'en'">Receive a DP Note/Invoice from HASINA (minimum DP 50%)</p>
            </div>
            <div class="feature-info-button">
              <a class="btn btn-light btn-block" href="https://wa.me/6285740177785?text=Halo+Hasina...+Ada+yang+ingin+saya+tanyakan+perihal+pembayaran" target="_blank">{{ translateAktif == 'id' ? 'Chat Sekarang' : 'Chat Now'}}</a>
            </div>
          </div>
          <div class="feature-info-bg bg-holder bg-overlay-black-70" style="background-image: url('../templates/default/images/property/grid/02.jpg');"></div>
        </div>
      </div>
      <div style="width:20%" class="">
        <div class="feature-info feature-info-02">
          <div class="feature-info-detail">
            <div class="feature-info-icon">
              <!-- <i class="flaticon-buy"></i> -->
              <img style="width:50%" class="img-fluid" :src="getImage(`assets/icon/pelunasan.png`)"  alt="">
            </div>
            <div class="feature-info-content">
              <h6 class="mb-3 feature-info-title">4. {{ translateAktif == 'id' ? 'Pelunasan' : 'Repayment'}}</h6>
              - {{ translateAktif == 'id' ? 'Pelunasan' : 'Repayment'}}<br>
              - {{ translateAktif == 'id' ? 'Konfirmasi Pembayaran' : 'Payment confirmation'}}<br>
              - {{ translateAktif == 'id' ? 'Menyelesaikan Pesanan' : 'Complete Order'}}
            </div>
            <div class="feature-info-button">
              <a class="btn btn-light btn-block" href="https://wa.me/6285740177785?text=Halo+Hasina...+Ada+yang+ingin+saya+tanyakan+perihal+pelunasan" target="_blank">{{ translateAktif == 'id' ? 'Chat Sekarang' : 'Chat Now'}}</a>
            </div>
          </div>
          <div class="feature-info-bg bg-holder bg-overlay-black-70" style="background-image: url('../templates/default/images/property/grid/02.jpg');"></div>
        </div>
      </div>
      <div style="width:20%" class="">
        <div class="feature-info feature-info-02">
          <div class="feature-info-detail">
            <div class="feature-info-icon">
              <!-- <i class="flaticon-shopping-cart"></i> -->
              <img style="width:50%" class="img-fluid" :src="getImage(`assets/icon/produksi.png`)"  alt="">
            </div>
            <div class="feature-info-content">
              <h6 class="mb-3 feature-info-title">3. {{ translateAktif == 'id' ? 'Produksi' : 'Phone'}}</h6>
              - {{ translateAktif == 'id' ? 'Proses Production' : 'Production Process'}}<br>
              - QC<br>
              - {{ translateAktif == 'id' ? 'Pengemasan' : 'Packing'}}
            </div>
            <div class="feature-info-button">
              <a class="btn btn-light btn-block" href="https://wa.me/6285740177785?text=Halo+Hasina...+Ada+yang+ingin+saya+tanyakan+perihal+produksi" target="_blank">{{ translateAktif == 'id' ? 'Chat Sekarang' : 'Chat Now'}}</a>
            </div>
          </div>
          <div class="feature-info-bg bg-holder bg-overlay-black-70" style="background-image: url('../templates/default/images/property/grid/02.jpg');"></div>
        </div>
      </div>
      <div style="width:20%" class="">
        <div class="feature-info feature-info-02">
          <div class="feature-info-detail">
            <div class="feature-info-icon">
              <!-- <i class="flaticon-send"></i> -->
              <img style="width:57%" class="img-fluid" :src="getImage(`assets/icon/pengiriman.png`)"  alt="">
            </div>
            <div class="feature-info-content">
              <h6 class="mb-3 feature-info-title">5. {{ translateAktif == 'id' ? 'Pengiriman' : 'Phone'}}</h6>
              <p v-if="translateAktif == 'id'">Pengiriman ke Alamat Customer</p>
              <p v-if="translateAktif == 'en'">Delivery to Customer Address</p>
            </div>
            <div class="feature-info-button">
              <a class="btn btn-light btn-block" href="https://wa.me/6285740177785?text=Halo+Hasina...+Ada+yang+ingin+saya+tanyakan+perihal+pengiriman" target="_blank">{{ translateAktif == 'id' ? 'Chat Sekarang' : 'Chat Now'}}</a>
            </div>
          </div>
          <div class="feature-info-bg bg-holder bg-overlay-black-70" style="background-image: url('../templates/default/images/property/grid/02.jpg');"></div>
        </div>
      </div>
    </div>
    <div class="hide-on-desktop row mt-4">
        <center>
        <a class="portfolio-img"><img class="img-fluid" :src="getImage(`assets/produk/order.jpg`)"  alt=""></a>
        </center>
    </div>
  </div>
</section>

    </div>
</template>

<script>

    export default {
        name: 'Hasina',
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Cara Order',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id'
              }
            ]
        },
        mounted() {
            this.getLocalStorage();
        },
        data() {
            return {
                translateAktif: this.$root.$translateAktif,
                username: "",
                password: "",
                submitted: false
            }
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            getLocalStorage(){
                this.translateAktif = localStorage.getItem('translate');
            }
        }
    }
</script>